import { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { renderFieldError } from "../../helper";
import Asterisk from "../../components/atoms/Asterisk";

const GuestPrimaryDetails = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const primaryFields = [
    {
      name: "firstName",
      label: "First Name",
      isRequired: true,
    },
    {
      name: "middleName",
      label: "Middle Name",
      isRequired: false,
    },
    {
      name: "lastName",
      label: "Last Name",
      isRequired: true,
    },
  ];

  useEffect(() => {
    setFieldValue("panCardDetails.lastName", values.profileDetails?.lastName || "");
  }, [values.profileDetails?.lastName]);

  useEffect(() => {
    const { isChild = false, title = "" } = values?.profileDetails || {};
  
    if (isChild && title === "Mrs") {
      setFieldValue("profileDetails.title", "Miss");
    } else if (!isChild && title === "Miss") {
      setFieldValue("profileDetails.title", "Mrs");
    }
  }, [values?.profileDetails?.isChild]);
  

  return (
    <>
      <div className="col-span-12 sm:col-span-6 md:col-span-2">
        <div className="form-group">
          <label
            htmlFor="title"
            className="block text-sm font-medium mb-1 text-contrast-900"
          >
            Title
          </label>
          <select
            id="title"
            name="profileDetails.title"
            value={values?.profileDetails?.title}
            className="form-control block w-full text-sm py-2 px-2 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
            onChange={(e) =>
              setFieldValue("profileDetails.title", e.target.value)
            }
          >
            <option value="Mr">Mr</option>
            {values?.profileDetails?.isChild ? <option value="Miss">Miss</option> : <option value="Mrs">Mrs</option>}
          </select>
        </div>
      </div>
      {primaryFields.map(({ name, label, isRequired }) => (
        <div key={name} className="col-span-12 sm:col-span-6 md:col-span-5">
          <div className="form-group">
            <label
              htmlFor={name}
              className="block text-sm font-medium mb-1 text-contrast-900"
            >
              {label}
              {isRequired && <Asterisk />}
            </label>
            <div>
              <Field
                id={name}
                type="text"
                name={`profileDetails.${name}`}
                className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                placeholder={label}
              />
            </div>
            <div>
              {renderFieldError(`profileDetails.${name}`, errors, touched)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default GuestPrimaryDetails;
