import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { getPriceAfterDiscount, getSurchargesAmount } from "../../helper";
import { selectPricePolicyInfo, selectSelectedRoomInfo } from "../HotelInfo";
import { selectHotelCurrency, selectHotelSearchFilters } from "../../components/organisms/Search";
import { DEFAULT_VALUES, TAVATRIP_FEE } from "../../constants";
import { ChevronDown, ChevronUp, Cross, RenderSVG } from "../../assets/icons";
import {
  selectSelectedPromoCode,
  setPromoCode,
} from "../Booking/FlightBookings";
import { useCollapse } from "react-collapsed";
import { formatPriceWithCommas, priceFormatter } from "../../helper/priceFormatter";
import TaxBreakDownSection from "./TaxBreakDownSection";

const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;

const PriceBreakdownSection = ({ showPaymentBreakdown = false , hotelTotalFare }) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 300,
    defaultExpanded: true,
  });
  const dispatch = useDispatch();
  const { symbol: hotelCurrencySymbol = "" } = useSelector(selectHotelCurrency) || {}
  const searchFilters = useSelector(selectHotelSearchFilters);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const selectedPromoCode = useSelector(selectSelectedPromoCode);
  const selectedRoomInfo = useSelector(selectSelectedRoomInfo);
  const [discountAmount, setDiscountAmount] = useState(ZERO);
  const [priceBreakDown, setPriceBreakDown] = useState({
    totalRoomPrice: ZERO,
    totalTaxesAndFees: ZERO,
    paymentGatewayCharge: ZERO,
    netPayable: ZERO,
  });
  const { netPayable, totalRoomPrice, totalTaxesAndFees, paymentGatewayCharge } = priceBreakDown;

  const priceInfo = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails",
    []
  );
  const { noOfNights = ZERO, noOfRooms = ZERO } = searchFilters || {};
  const formattedValue= netPayable - discountAmount;
  useEffect(() => {
    hotelTotalFare.current = formattedValue;
}, [formattedValue, hotelTotalFare]);

  useEffect(() => {
    let totalRoomPrice = ZERO;
    let totalTax = ZERO;
    let paymentGatewayCharge = ZERO;
    const roomInfo = !isEmpty(priceInfo) ? priceInfo : selectedRoomInfo;

    if (!isEmpty(roomInfo)) {
      const { roomPrice, tax } = roomInfo.reduce(
        (acc, room) => {
          const roomPrice =
            (room.price?.roomPrice || ZERO) + (room.price?.tavaMarkup || ZERO);
          const tax = room.price?.tax || ZERO;
          const otherCharges = room.price?.otherCharges || ZERO;
          return {
            roomPrice: acc.roomPrice + roomPrice,
            tax: acc.tax + tax + otherCharges,
          };
        },
        { roomPrice: ZERO, tax: ZERO }
      );

      totalRoomPrice = roomPrice;
      totalTax = tax 
      paymentGatewayCharge = getSurchargesAmount(totalRoomPrice + tax);
    }
    setPriceBreakDown({
      totalRoomPrice,
      totalTaxesAndFees: totalTax,
      paymentGatewayCharge,
      netPayable: totalRoomPrice + totalTax + paymentGatewayCharge,
    });
  }, [priceInfo, selectedRoomInfo]);

  useEffect(() => {
    if (isEmpty(selectedPromoCode)) setDiscountAmount(ZERO);
    else {
      const finalPrices = getPriceAfterDiscount(selectedPromoCode, netPayable);
      const updatedPrice = finalPrices.updatedPrice;

      if (updatedPrice !== selectedPromoCode.offeredPrice) {
        const promoCode = {
          ...selectedPromoCode,
          offeredPrice: updatedPrice,
          ...(finalPrices.discountAmount && {
            discountAmount: finalPrices.discountAmount,
          }),
        };
        dispatch(setPromoCode(promoCode));
        setDiscountAmount(finalPrices.discountAmount);
      }
    }
  }, [dispatch, selectedPromoCode, netPayable]);

  return (
    <>
      <div className="border border-contrast-300 rounded-lg mb-6 bg-white">
        <ul className="flex flex-col text-sm !divide-y">
          <li className="px-4 py-3 flex justify-between">
            <h4 className="text-contrast-900 font-bold text-base">
              Price breakdown
            </h4>
            <div {...getToggleProps()}>
              <span className="flex gap-2 items-center text-sm text-primary-600">
                <RenderSVG
                  Svg={isExpanded ? ChevronUp : ChevronDown}
                  className="w-4 h-4 text-black"
                  alt={isExpanded ? "Up Icon" : "Down Icon"}
                />
              </span>
            </div>
          </li>
          <div {...getCollapseProps()}>
            <li className="flex items-center gap-2 px-4 py-3">
              <div className="mr-auto flex flex-col">
                <span className="font-semibold text-contrast-900">
                  {noOfRooms} Room x {`${noOfNights} Night${noOfNights > 1 ? "s" : ""}`}
                </span>
                <span className="text-xs text-contrast-500">Base price</span>
              </div>
              <span className="font-medium text-base whitespace-nowrap">
                {hotelCurrencySymbol}&nbsp;
                {formatPriceWithCommas(totalRoomPrice)}
              </span>
            </li>
            <li className="flex items-center gap-2 px-4 py-3">
              <span className=" mr-auto text-base text-contrast-900">
                Hotel Taxes &amp; Fees
              </span>
              <span className="font-medium text-base whitespace-nowrap">
                {hotelCurrencySymbol}&nbsp;
                {formatPriceWithCommas(totalTaxesAndFees)}
              </span>
            </li>
            <li className="flex items-center gap-2 px-4 py-3">
              <TaxBreakDownSection paymentGatewayCharge ={paymentGatewayCharge - TAVATRIP_FEE}
                currencySymbol={hotelCurrencySymbol} 
                isHotel={true}
                />
            </li>
            {!isEmpty(selectedPromoCode) && (
              <li className="flex items-center gap-2 px-4 py-3">
                <span className=" mr-auto text-green-700 font-semibold">
                  Total Discount
                </span>
                <div className="flex items-center justify-between font-medium text-green-600">
                  <span className="font-semibold text-base text-green-700 whitespace-nowrap">
                    {hotelCurrencySymbol}&nbsp;
                    {formatPriceWithCommas(discountAmount)}
                  </span>
                  {!isEmpty(selectedPromoCode) && (
                    <button
                      type="button"
                      className="flex items-center justify-end pl-2"
                      onClick={() => dispatch(setPromoCode(EMPTY_OBJECT))}
                    >
                      <RenderSVG
                        Svg={Cross}
                        alt="Check Icon"
                        className="h-4 relative rounded-full"
                        color="#FF0000"
                      />
                    </button>
                  )}
                </div>
              </li>
            )}
          </div>
          <li className="flex items-center gap-2 px-4 py-3 bg-primary-100/50">
            <span className="text-base font-bold mr-auto text-primary-600">
              Total Due
            </span>
            <span className="text-base font-bold text-primary-600 whitespace-nowrap">
              {hotelCurrencySymbol}&nbsp;
              {formatPriceWithCommas(netPayable - discountAmount)}
            </span>
          </li>
        </ul>
      </div>

      {showPaymentBreakdown && (
        <div className="border border-contrast-300 rounded-lg mb-6 bg-white">
          <ul className="flex flex-col text-sm divide-y">
            <li className="px-4 py-3 flex justify-between flex-wrap">
              <h4 className="text-contrast-900 font-bold text-base">Amount Paid</h4>
              <span className="text-base font-bold text-primary-600">
                {hotelCurrencySymbol}&nbsp;
                {priceFormatter(discountAmount)}
              </span>
            </li>
            <li className="flex items-center gap-2 px-4 py-3">
              <div className="mr-auto flex flex-col">
                <span className="font-semibold text-contrast-900">
                  <span className="text-teal-900 py-2">
                    Amount Pending
                    <p className="font-normal text-xs text-teal-600">
                      Pay the remaining amount of {hotelCurrencySymbol}&nbsp;
                      {formatPriceWithCommas(netPayable - discountAmount)} using
                      any payment option before{" "}
                      {getFormattedDateAndTime(lastCancellationDate)} to avoid
                      auto cancellation
                    </p>
                  </span>
                </span>
              </div>
            </li>
            <li className="flex items-center gap-2 px-4 py-3">
              <span className=" mr-auto text-green-700 font-semibold text-xs">
                Pay Now
              </span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default PriceBreakdownSection;
