import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import StayDateInfo from "./StayDateInfo";
import {
  getFromSessionStorage,
  loadScript,
  getFormattedLongDate,
  setToSessionStorage,
  getNextDate,
  renderStars,
  getFormattedAddress,
  getCancellationCharges,
  getFormattedDate,
  getCurrentDateTime,
  getHotelStayDates,
} from "../../helper";
import {
  selectHotelInfo,
  selectPricePolicyInfo,
  selectPricePolicyReqBody,
  selectSelectedRoomInfo,
} from "../HotelInfo";
import { mapGenericBookingRequest } from "../../helper/mapHotelBookingAPIRequest";
import { actions as hotelActions } from "../HotelInfo/hotelInfo.reducer";
import { actions as hotelBookingActions } from "../HotelBooking/hotelBooking.reducer";
import { Check, CrossRed, RenderSVG } from "../../assets/icons";
import { selectHotelCurrency, selectHotelSearchFilters } from "../../components/organisms/Search";
import Spinner, {
  SPINNER_NAMES,
  selectActiveSpinners,
} from "../../components/organisms/Spinner";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import GuestDetailsSection from "./GuestDetailsSection";
import { selectCountryInfo, selectUserInfo } from "../Profile";
import PriceBreakdownSection from "./PriceBreakdownSection";
import HotelDetailsChangeModal from "../../components/organisms/AppModals/HotelDetailsChangeModal/HotelDetailsChangeModal";
import CancellationPolicyInfo from "./CancellationPolicyInfo";
import { setSelectedModal } from "../../components/organisms/Modal";
import { MODALS } from "../../components/organisms/AppModals";
import {
  selectSelectedPromoCode,
  setPromoCode,
} from "../Booking/FlightBookings";
import PromoCodeCard from "../../components/molecules/PromoCodeCard";
import {
  initiateHotelBooking,
  selectLeadGuestInfo,
  selectOtherGuests,
  setOtherGuests,
} from "../HotelBooking";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  BOOKING_TYPES,
  RAZORPAY_CHECKOUT_SCRIPT_URL,
  CANCELLATION_CHARGE_TYPES
} from "../../constants";
import config from "../../config.json";
import { checkingGuestValidation, getPanValidationSchema, getPassportValidationSchema, getPrimaryPaxValidationSchema } from "../../helper/hotelBookingHelpers";

const { brandName } = config;
const { EMPTY_OBJECT, EMPTY_ARRAY, ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { PERCENTAGE, AMOUNT, NIGHTS } = CANCELLATION_CHARGE_TYPES;
const { PRICE_POLICY_REQUEST_BODY, BOOKING_INFORMATION } = CACHE_KEYS;
const { VERIFY_HOTEL_PRICE_POLICY, HOTEL_BOOKING } = SPINNER_NAMES;
const { LOGIN_MODAL, HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const {
  HOTEL_CONFIRM,
  HOTEL_PAYMENT,
  TERMS_AND_CONDITION,
  CANCELLATION_AND_REFUND_POLICY,
} = ROUTES;
const { NON_VOUCHER_BOOKING, VOUCHER_BOOKING } = BOOKING_TYPES;
const ERROR_VISIBILITY_DURATION = 3000;
const { AMENITIES_MODAL } = MODALS;

const { setPricePolicyReqBody } = hotelActions;
const { setHotelBookReqBody, setHotelBookRes } = hotelBookingActions;

const hotelGuidelines = [
  {
    id: 1,
    data: "Passport, Aadhaar, Driving License and Govt. ID are accepted as ID proof(s)",
  },
];

const HotelReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const leadGuestFormRef = useRef(null);
  const guestDetailsRef = useRef(null);
  const activeSpinners = useSelector(selectActiveSpinners);
  const userInfo = useSelector(selectUserInfo);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const hotelCurrency = useSelector(selectHotelCurrency) || {}
  const selectedRoomDetails = useSelector(selectSelectedRoomInfo);
  const pricePolicyReqBody = useSelector(selectPricePolicyReqBody);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const leadGuest = useSelector(selectLeadGuestInfo);
  const otherGuests = useSelector(selectOtherGuests);
  const appliedPromoCode = useSelector(selectSelectedPromoCode);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [bookingType, setBookingType] = useState(VOUCHER_BOOKING);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPaymentButtonClicked, setIsPaymentButtonClicked] = useState(false);
  const [isBookingInProcess, setIsBookingInProcess] = useState(false);
  const [
    isUserAgreementsAndCancellationPolicyChecked,
    setIsUserAgreementsAndCancellationPolicyChecked,
  ] = useState(false);
  const [agreementsSectionHighlighted, setAgreementsSectionHighlighted] =
    useState(false);
  const isUserAuthenticated = !!userInfo?.id;
  const selectedOtherGuests = otherGuests.filter((guest) => guest.isSelected);
  const isPromoCodeApplied = !isEmpty(appliedPromoCode);
  const nextDate = getNextDate(new Date());
  const { email: loggedInUserEmail } = userInfo || {};
  const hotelInfo = get(pricePolicyInfo, "blockRoomResult", EMPTY_OBJECT);
  const hotelImages = get(
    selectedHotelInfo,
    "priceResult.hotelDetails.images",
    EMPTY_OBJECT
  );
  const { hotelRoomsDetails = [] } = hotelInfo;

  const { totalDays = ZERO } = getHotelStayDates(selectedHotelInfo, hotelInfo)
   
  const noOfNights = totalDays + ONE || ONE;

  const { validationAtConfirm = {}, validationAtVoucher = {} } =
    get(hotelInfo, "validationInfo") || {};

  const isPANRequired =
    validationAtConfirm?.isPanMandatory || validationAtVoucher?.isPanMandatory;
  const isPassportRequired =
    validationAtConfirm?.isPassportMandatory ||
    validationAtVoucher?.isPassportMandatory;
  const isSamePanForAllAllowed =
    validationAtConfirm?.isSamePanForAllAllowed ||
    validationAtVoucher?.isSamePanForAllAllowed;
  const areAllGuestDetailsRequired = true;
  const lowestLastCancellationDate = hotelRoomsDetails.reduce(
    (minDate, roomDetail) => {
      const roomLastVoucherDate = new Date(roomDetail.lastVoucherDate);
      return roomLastVoucherDate < minDate ? roomLastVoucherDate : minDate;
    },
    nextDate
  );

  const toShowNonVoucherBooking =
    !isEmpty(hotelRoomsDetails) && lowestLastCancellationDate >= new Date();

  const { cancellationPolicies = [] } = selectedRoomDetails[ZERO] || {};
  const { roomGuests = [] } = searchFilters || {};
  const { isCancellationPolicyChanged, isHotelPolicyChanged, isPriceChanged } =
    hotelInfo || {};
  const hotelPolicyDetail =
    get(selectedHotelInfo, "priceResult.hotelDetails.hotelPolicy", "") ||
    hotelInfo?.hotelPolicyDetail;
  const hotelPolicyUpdatedPrice = get(
    hotelInfo,
    "hotelRoomsDetails.0.price.publishedPriceRoundedOff"
  );
  const { hotelName, starRating } = !!Object.keys(selectedHotelInfo).length
    ? get(selectedHotelInfo, "priceResult.hotelDetails", "")
    : hotelInfo;
  const address =
    get(selectedHotelInfo, "priceResult.hotelDetails.address", "") ||
    hotelInfo?.addressLine1;
  const hotelCode = get(pricePolicyReqBody, "hotelCode", EMPTY_STRING);
  const freeCancellationBeforeDate =
    cancellationPolicies[ZERO]?.chargeType !== ONE
      ? cancellationPolicies[ZERO]?.fromDate
      : cancellationPolicies[ZERO]?.charge
      ? cancellationPolicies[ZERO]?.fromDate
      : cancellationPolicies[ZERO]?.toDate;
  let nonRefundable = [];
  const partialRefundable = [];
  const amenityDetails = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails[0].amenity",
    EMPTY_ARRAY
  );
  cancellationPolicies.forEach((item) => {
    if(item.charge === 0) return
    if (item.charge === 100 && item.chargeType === PERCENTAGE) {
      nonRefundable.push(item);
    } else {
      partialRefundable.push(item);
    }
  });
  let nonRefundableFromDate = nonRefundable[ZERO]?.fromDate;

  const totalGuests = roomGuests.reduce(
    (total, guestObject) =>
      total + guestObject["noOfAdults"] + guestObject["noOfChild"],
    ZERO
  );

  const roomAndBedType = (roomDetail) =>
    roomDetail?.[0]?.roomTypeName?.split(",") || [];
  const getGuestsCount = (guests = []) => {
    return guests.reduce(
      (total, guestObject) =>
        total + guestObject["noOfAdults"] + guestObject["noOfChild"],
      ZERO
    );
  };

  const resetGuestsSelection = () => {
    const updatedOtherGuests = otherGuests.map((guest) => ({
      ...guest,
      isSelected: false,
      roomIndex: null,
    }));
    dispatch(setOtherGuests(updatedOtherGuests));
  };

  useEffect(() => {
    resetGuestsSelection();
    if (isEmpty(pricePolicyReqBody)) {
      const data = getFromSessionStorage(PRICE_POLICY_REQUEST_BODY);
      dispatch(setPricePolicyReqBody(data));
    }
    return () => {
      dispatch(setPromoCode());
    };
  }, [dispatch]);
  useEffect(() => {
    if (isCancellationPolicyChanged || isHotelPolicyChanged)
      dispatch(setSelectedModal(HOTEL_DETAILS_CHANGE_MODAL));
  }, [dispatch, hotelInfo]);

  useEffect(() => {
    const leadGuestFirstName = get(leadGuest, "profileDetails.firstName", "");
    isPaymentButtonClicked && leadGuestFirstName && initiatePayment();
  }, [leadGuest, isPaymentButtonClicked, isSpinnerActive]);

  useEffect(() => {
    isPromoCodeApplied && setBookingType(VOUCHER_BOOKING);
  }, [isPromoCodeApplied]);

  const handleOptionChange = (e) => {
    setBookingType(e.target.value);
  };

  const showErrorMessage = (msg) => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage("");
    }, ERROR_VISIBILITY_DURATION);
  };

  const getPaymentButtonText = () => {
    if (bookingType !== NON_VOUCHER_BOOKING) return t("hotelResult.payNow");
    return isUserAuthenticated
      ? t("hotelResult.bookNow")
      : t("hotelResult.loginInToBookNow");
  };

  const verifyGuestCountValidation = (inValidGuestData) => {
    if (totalGuests > ONE && selectedOtherGuests.length !== totalGuests - ONE) {
      const remainingGuests = totalGuests - selectedOtherGuests.length - ONE;
      showErrorMessage(
        `Please add ${remainingGuests} other guests before proceeding`
      );
    } else {
      if (!isEmpty(inValidGuestData)) {
        showErrorMessage("Please fill all the required details of each guest correctly");
        return;
      }
      setIsPaymentButtonClicked(true);
      setErrorMessage("");
    }
  };

  const passportValidationSchema = getPassportValidationSchema();
  const panValidationSchema = getPanValidationSchema();
  const baseValidationSchema = {
    profileDetails: Yup.object().shape(getPrimaryPaxValidationSchema(t)),
  };
  const guestValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPassportRequired && passportValidationSchema),
    ...(isPANRequired && !isSamePanForAllAllowed && panValidationSchema.fields),
  });

  const initiatePayment = async () => {
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript || isBookingInProcess) return;
    setIsBookingInProcess(true);

    const bookingAPIRequest = mapGenericBookingRequest({
      pricePolicyReqBody,
      pricePolicyInfo,
      hotelCurrency,
      voucherBookingType: bookingType,
      leadGuest,
      otherGuests: selectedOtherGuests,
      isSamePanForAllAllowed,
      isPANRequired,
      roomGuests,
      loggedInUserEmail,
      bookingType: "hotel",
      hotelImage: hotelImages?.[ZERO],
    });

    const tavaBookingId = bookingAPIRequest?.hotelBookingRequest?.bookingRequest?.tavaBookingId;
    dispatch(setHotelBookReqBody(bookingAPIRequest));
    const initiateBookingRequest = {
      requestBody: {
        ...bookingAPIRequest,
      },
    };

    dispatch(initiateHotelBooking(initiateBookingRequest)).then((res) => {
      setIsPaymentButtonClicked(false);
      setIsBookingInProcess(false);
      if (!res.payload) return;
      const updatedBookingAPIRes = {
        ...res.payload,
        tavaBookingId,
      };
      setToSessionStorage(BOOKING_INFORMATION, updatedBookingAPIRes);
      if (bookingType != NON_VOUCHER_BOOKING)
        navigate(HOTEL_PAYMENT.replace(":hotelId", hotelCode));
      dispatch(setHotelBookRes(updatedBookingAPIRes));
    });

    if (bookingType === NON_VOUCHER_BOOKING)
      navigate(HOTEL_CONFIRM.replace(":hotelId", hotelCode));
  };

  const handlePayment = () => {
    if (!isUserAuthenticated && bookingType === NON_VOUCHER_BOOKING) {
      dispatch(setSelectedModal(LOGIN_MODAL));
      return;
    }

    const inValidGuestData = checkingGuestValidation(
      guestValidationSchema,
      otherGuests
    )

    const { isValid, handleSubmit } = leadGuestFormRef.current || {};
    if (isUserAgreementsAndCancellationPolicyChecked || !isValid) {
      handleSubmit();
      isValid
        ? verifyGuestCountValidation(inValidGuestData)
        : guestDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setAgreementsSectionHighlighted(true);
      setTimeout(
        () => setAgreementsSectionHighlighted(false),
        ERROR_VISIBILITY_DURATION
      );
    }
  };

  const isCurrentAndnonRefundableFromDateSame = () => {
    return getCurrentDateTime() === getFormattedDate(freeCancellationBeforeDate) ? true : false;
  }
  const isChargeThreeMakingNonRefundable = (charge, fromDate) => {
    const res = (charge - noOfNights) == ZERO
    if (res) nonRefundableFromDate = fromDate;
    return res;
  }
  const isChargeOneValid = (charge, fromDate) => {
    const isChargeValid = (hotelTotalFare - charge) > ZERO
    if (!isChargeValid) {
      nonRefundableFromDate = fromDate;
    }
    return isChargeValid;
  }
  const isPartialRefundSectionValid = (charge, chargeType, fromDate) => (charge && (chargeType == NIGHTS ? !isChargeThreeMakingNonRefundable(charge, fromDate) : true) && (chargeType == AMOUNT ? isChargeOneValid(charge, fromDate) : true));
  const valueRef = useRef(null);
  const hotelTotalFare= valueRef.current;

  return (
    <div className="flex items-center min-h-[80vh]">
      <Helmet>
        <title>{brandName} | Review Hotel</title>
      </Helmet>
      <div className="w-full">
        <header className="relative bg-primary-800">
          <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-primary-900/50"></div>
          <div className="container px-8 pt-8 pb-16 mx-auto relative">
            <h4 className="text-xl text-white mb-1 font-bold">
              Review your Booking
            </h4>
          </div>
        </header>
        <main className="pb-16 relative">
          <div className="container px-4 md:px-8 mx-auto -mt-8">
            <div className="grid grid-cols-12 gap-3 md:gap-8 relative">
              <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
                <div className="flex flex-col gap-8">
                  <div className="bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm">
                    <div className="flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between">
                      <div className="flex-1">
                        <h4 className="text-contrast-900 font-bold text-base mb-2">
                          {hotelName}
                        </h4>
                        <p className="text-contrast-500 text-xs mb-2">
                          {getFormattedAddress(address)}
                        </p>
                        <div className="flex gap-2 items-center mb-2">
                          <div className="flex items-center gap-0.5">
                            {renderStars(starRating)}
                          </div>
                        </div>
                      </div>
                      <div className="shrink-0">
                        <img
                          src={hotelImages?.[ZERO]}
                          alt={hotelName}
                          className="w-full rounded-lg h-28"
                        />
                      </div>
                    </div>
                    <StayDateInfo/>
                    <div className="px-4 py-4 border-t border-dashed border-contrast-300">
                      <div className="flex items-center justify-between mb-3">
                        <div>
                          <h4 className="text-base font-bold text-contrast-900 flex-1">
                            {roomAndBedType(selectedRoomDetails)[ZERO]}
                          </h4>
                          <p className="text-xs text-contrast-800">
                            {getGuestsCount(roomGuests)} Guest
                          </p>
                        </div>
                        {!!amenityDetails.length && (
                          <button
                            onClick={() =>
                              dispatch(setSelectedModal(AMENITIES_MODAL))
                            }
                            className="font-semibold text-primary-700 hover:opacity-90 text-xs"
                          >
                            See Amenities
                          </button>
                        )}
                      </div>
                      <ul className="flex flex-col gap-1 font-medium mb-3">
                        {isCurrentAndnonRefundableFromDateSame() ? "" :
                          <li className='flex items-center gap-2'>
                            <RenderSVG
                              Svg={Check}
                              width='14'
                              className='text-green-700'
                            />
                            <span className='text-xs text-green-700'>
                              Free cancellation before{" "}
                              {getFormattedLongDate(freeCancellationBeforeDate, {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}.
                            </span>
                          </li>
                        }
                        {partialRefundable.map(
                          (
                            { charge, chargeType, fromDate, toDate, currency },
                            index
                          ) => {
                            const refundability = `${getCancellationCharges(
                              charge,
                              chargeType,
                              currency
                            )}`;
                            if (isPartialRefundSectionValid(charge, chargeType, fromDate)) {
                              return (
                                <li key={index}>
                                  <div className="flex items-center">
                                    <RenderSVG
                                      Svg={Check}
                                      width="14"
                                      className="text-yellow-700 inline-block"
                                    />
                                    <span className="ml-2 text-xs text-yellow-700">
                                      {refundability} amount will be charged if
                                      cancelled between{" "}
                                      {getFormattedLongDate(fromDate, {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}{" "}
                                      to{" "}
                                      {getFormattedLongDate(toDate, {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </span>
                                  </div>
                                </li>
                              )
                            };
                          }
                        )}

                        <li className="flex items-center gap-2">
                          <RenderSVG
                            Svg={CrossRed}
                            width="8"
                            className="text-red-700"
                          />
                          {isCurrentAndnonRefundableFromDateSame() ?
                            <span className='text-xs text-red-700 ml-1'>
                              No Refund available.
                            </span>
                            :
                            <span className='text-xs text-red-700 ml-1'>
                              No Refund from{" "}
                              {getFormattedLongDate(nonRefundableFromDate, {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}{" "}onwards.
                            </span>
                          }
                        </li>
                      </ul>
                      <div className="hidden xs:block">
                        <CancellationPolicyInfo
                          cancellationPolicies={cancellationPolicies}
                          noOfNights={noOfNights}
                          hotelTotalFare={hotelTotalFare}
                        />
                      </div>
                    </div>

                    <div className="p-4 bg-amber-50">
                      <div className="flex items-center mb-3">
                        <h4 className="text-base font-bold text-amber-500 flex-1 justify-between">
                          Important Information
                        </h4>
                      </div>
                      <ul className="list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1">
                        {hotelGuidelines.map(({ id, data }) => (
                          <li key={id} className="list-item">
                            {data}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <GuestDetailsSection
                  leadGuestFormRef={leadGuestFormRef}
                  guestDetailsRef={guestDetailsRef}
                  isPANRequired={isPANRequired}
                  isPassportRequired={isPassportRequired}
                  isSamePANForAllAllowed={isSamePanForAllAllowed}
                  areAllGuestDetailsRequired={areAllGuestDetailsRequired}
                />
                <div className="rounded-lg border border-contrast-300 shadow-sm bg-white p-4">
                  <h4 className="text-base font-bold text-contrast-900 flex-1 mb-6">
                    Payment Options
                  </h4>
                  <div className="flex flex-col gap-4">
                    <div className="checbox-tab">
                      <label className="cursor-pointer relative">
                        <input
                          type="radio"
                          className="peer hidden"
                          onChange={handleOptionChange}
                          name="payment option"
                          value={VOUCHER_BOOKING}
                          checked={bookingType === VOUCHER_BOOKING}
                        />
                        <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                        <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                          <h6 className="text-sm font-semibold flex uppercase">
                            Make full payment
                          </h6>
                          <span className="text-xs flex">
                            Cancel for free any time before{" "}
                            {getFormattedLongDate(
                              cancellationPolicies[ZERO]?.fromDate,
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </label>
                    </div>

                    {toShowNonVoucherBooking && (
                      <div
                        className={classNames("checbox-tab", {
                          "bg-contrast-100": isPromoCodeApplied,
                        })}
                      >
                        <label className="cursor-pointer relative">
                          <input
                            type="radio"
                            className="peer hidden"
                            onChange={handleOptionChange}
                            name="payment option"
                            value={NON_VOUCHER_BOOKING}
                            checked={bookingType === NON_VOUCHER_BOOKING}
                            disabled={isPromoCodeApplied}
                          />
                          <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                          <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                            <h6 className="text-sm font-semibold flex uppercase">
                              Book with ₹0 payment
                            </h6>

                            <span className="text-xs flex">
                              {isPromoCodeApplied
                                ? "You need remove the coupon code to avail this option"
                                : "Confirm your booking and Pay Later"}
                            </span>
                          </div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={classNames("flex flex-row items-center mb-2 ", {
                      "border-2 error-animate bg-red-100/20 p-1.5":
                        agreementsSectionHighlighted,
                    })}
                  >
                    <input
                      className="w-5 h-5 mr-3"
                      type="checkbox"
                      checked={isUserAgreementsAndCancellationPolicyChecked}
                      onChange={() => {
                        setIsUserAgreementsAndCancellationPolicyChecked(
                          !isUserAgreementsAndCancellationPolicyChecked
                        );
                      }}
                    />
                    <p className="text-xs">
                      I agree to Tavatrip's{" "}
                      <button
                        className="text-primary-700"
                        onClick={() =>
                          window.open(TERMS_AND_CONDITION, "_blank")
                        }
                      >
                        User Agreement
                      </button>{" "}
                      and{" "}
                      <button
                        className="text-primary-700"
                        onClick={() =>
                          window.open(CANCELLATION_AND_REFUND_POLICY, "_blank")
                        }
                      >
                        Cancellation Policy
                      </button>
                    </p>
                  </div>
                  <div className="flex md:justify-start">
                    <button
                      className={classNames(
                        "py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-52 h-11 disabled:cursor-not-allowed disabled:opacity-50",
                        {
                          "opacity-50": isSpinnerActive,
                        }
                      )}
                      onClick={handlePayment}
                      disabled={activeSpinners.some(
                        (spinnerName) =>
                          spinnerName === VERIFY_HOTEL_PRICE_POLICY ||
                          spinnerName === HOTEL_BOOKING
                      )}
                    >
                      <Spinner
                        name={HOTEL_BOOKING}
                        setIsSpinnerActive={setIsSpinnerActive}
                      >
                        {getPaymentButtonText()}
                      </Spinner>
                    </button>
                  </div>
                  <ErrorMessage errorMessage={errorMessage} />
                </div>
              </div>
              <div className="col-span-12 xl:col-span-4 sticky top-0">
                <PriceBreakdownSection hotelTotalFare={valueRef}/>
                <PromoCodeCard isHotel={true}/>
              </div>
            </div>
          </div>
        </main>
      </div>
      <HotelDetailsChangeModal
        hasPriceChanged={isPriceChanged}
        havePoliciesChanged={
          isHotelPolicyChanged || isCancellationPolicyChanged
        }
        updatedCancellationPolicy={hotelPolicyDetail}
        updatedPrice={hotelPolicyUpdatedPrice}
      />
    </div>
  );
};

export default HotelReview;
