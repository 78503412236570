import { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import get from "lodash/get";
import ErrorMessage from "../../atoms/ErrorMessage";
import Asterisk from "../../atoms/Asterisk";
import { useTranslation } from "react-i18next";
import { TITLES } from "../../../constants";

const MALE = "male";
const FEMALE = "female";
const MALE_TITLES = ["Mr", "Mstr"];

const TravelerPrimaryDetailFeilds = ({ travelerType }) => {
  const { t } = useTranslation();
  const { errors, touched, values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("panCardDetails.lastName", values.profileDetails?.lastName || "");
  }, [values.profileDetails?.lastName]);

  const renderFieldError = (name) =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const handleTitleChange = (e) => {
    setFieldValue("profileDetails.title", e.target.value);
    if (MALE_TITLES.includes(e.target.value))
      setFieldValue("profileDetails.gender", MALE);
    else setFieldValue("profileDetails.gender", FEMALE);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value.trim());
  };

  return (
    <>
      <div className="col-span-12 sm:col-span-6 md:col-span-4">
        <div className="form-group">
          <label className="block text-sm font-medium mb-1 text-contrast-900">
            {t("profilePage.firstName")}
            <Asterisk />
          </label>
          <Field id="firstName" name="profileDetails.firstName">
            {({ field }) => (
              <div className="flex w-full border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                <select
                  name="profileDetails.title"
                  value={values.profileDetails.title}
                  className="rounded-md py-2 pl-2 border-0 block text-sm shadow-none pr-7"
                  onChange={(e) => handleTitleChange(e)}
                >
                  {TITLES[travelerType].map((title) => (
                    <option key={title} value={title} className="text-sm">
                      {title}
                    </option>
                  ))}
                </select>
                <div className="border-[1px]" />
                <input
                  {...field}
                  type="text"
                  className="form-control block w-full text-sm rounded-md py-2 px-3 border-0 placeholder:text-blue-contrast-500 shadow-none"
                  placeholder={t("profilePage.placeholders.firstName")}
                  onBlur={handleInputChange}
                />
              </div>
            )}
          </Field>
          <div>{renderFieldError("profileDetails.firstName")}</div>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-6 md:col-span-4">
        <div className="form-group">
          <label className="block text-sm font-medium mb-1 text-contrast-900">
            {t("profilePage.middleName")}
          </label>
          <Field
            id="middleName"
            name="profileDetails.middleName"
            type="text"
            className="form-control h-[38px] block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
            placeholder={t("profilePage.placeholders.middleName")}
            onBlur={handleInputChange}
          />
          <div>{renderFieldError("profileDetails.middleName")}</div>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-6 md:col-span-4">
        <div className="form-group">
          <label className="block text-sm font-medium mb-1 text-contrast-900">
            {t("profilePage.lastName")}
          </label>
          <Field
            id="lastName"
            name="profileDetails.lastName"
            type="text"
            className="w-full h-[38px] text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
            placeholder={t("profilePage.placeholders.lastName")}
            onBlur={handleInputChange}
          />
          <div>
            {renderFieldError("profileDetails.lastName", errors, touched)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelerPrimaryDetailFeilds;
