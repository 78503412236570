import { ChevronDown, ChevronUp, RenderSVG } from "../../assets/icons";
import { useCollapse } from "react-collapsed";
import {
  formatPriceWithCommas,
  priceFormatter,
} from "../../helper/priceFormatter";
import { useTranslation } from "react-i18next";
import { TAVATRIP_FEE } from "../../constants";

const TAVA_TRIP_FEE = 1
const TAVA_TRIP_FEE_GST = 0.18

const TaxBreakDownSection = ({ paymentGatewayCharge, currencySymbol, isHotel = false }) => {
  const { t } = useTranslation();
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 300,
    defaultExpanded: false,
  });
  
  const formattedGatewayFees = Number(
    priceFormatter(paymentGatewayCharge).replaceAll(",", "")
  );
  const totalCharges = formatPriceWithCommas(
    (isHotel ? paymentGatewayCharge : formattedGatewayFees) + TAVATRIP_FEE
  );
  
  return (
    <div className="w-full rounded-lg mb-6 bg-white">
      <ul className="flex flex-col text-sm !divide-y">
        <li className="flex justify-between">
          <h4 className="text-contrast-900 text-base">
            {t("travelerInfo.fareCard.otherCharges")}
          </h4>
          <div {...getToggleProps()} className="flex items-center gap-1">
            <h4>
              <RenderSVG
                Svg={isExpanded ? ChevronUp : ChevronDown}
                className="w-4 h-4 text-black"
                alt={isExpanded ? "Up Icon" : "Down Icon"}
              />
            </h4>
            <h4 className="text-contrast-900 font-medium text-base">
              {currencySymbol}
              {totalCharges}
            </h4>
          </div>
        </li>
        <div {...getCollapseProps()}>
          <li className="flex items-center gap-2  py-3">
            <div className="mr-auto flex flex-col">
              <span className="text-contrast-900">
                {t("travelerInfo.fareCard.surcharges")}
              </span>
            </div>
            <span className="font-medium whitespace-nowrap">
              {currencySymbol}
              {isHotel ? formatPriceWithCommas(paymentGatewayCharge) : priceFormatter(paymentGatewayCharge)}
            </span>
          </li>
          <li className="flex items-center gap-2  py-3">
            <div className="mr-auto flex flex-col">
              <span className="text-contrast-900">
                {t("travelerInfo.fareCard.platformFee")}
              </span>
            </div>
            <span className="font-medium whitespace-nowrap">
              {currencySymbol}
              {TAVA_TRIP_FEE}
            </span>
          </li>
          <li className="flex items-center gap-2 pt-3">
            <div className="mr-auto flex flex-col">
              <span className="text-contrast-900">
                {t("profilePage.gstDetails.gst")}
              </span>
            </div>
            <span className="font-medium whitespace-nowrap">
              {currencySymbol}
              {TAVA_TRIP_FEE_GST}
            </span>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default TaxBreakDownSection;
