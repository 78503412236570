import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
  closeRazorPayModal,
  getFromSessionStorage,
  getLeadGuestInfo,
} from "../../../helper";
import { selectCountryInfo } from "../../Profile";
import {
  hotelBookCallback,
  selectLeadGuestInfo,
  setHotelBookCallbackRes,
  updateHotelBookingStatus,
  generatePaymentVoucher,
} from "../index";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  DEFAULT_CURRENCY_CODE,
  BOOKING_STATUS,
  SEARCH_SECTION,
} from "../../../constants";
import config from "../../../config.json";
import {
  getUserBookingInfo,
  selectProcessPaymentRes,
  setIsGenerateVoucherInitiated,
  selectSelectedBooking,
} from "../../MyTrips";
import { getPaymentMethod } from "../../Booking/FlightBookings";
import { selectHotelCurrency } from "../../../components/organisms/Search";

const { brandName } = config;
const { ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { BOOKING_INFORMATION, PRICE_POLICY_REQUEST_BODY } = CACHE_KEYS;
const { HOTEL_CONFIRM, HOTEL_REVIEW, USER_BOOKINGS, BOOKING_INFO_ROUTE } =
  ROUTES;
const { CANCELLED } = BOOKING_STATUS;
const { HOTEL } = SEARCH_SECTION;

const DEFAULT_PAYMENT_GATEWAY = "RAZORPAY";
const HOTELS_PAYMENT_MODAL_TITLE = "TavaTrip | Hotels";
const MOCK_SESSION_ID = "MOCK_SESSION_ID";

const HotelPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [razorpayPaymentObject, setRazorpayPaymentObject] = useState({});
  const isNonVoucheredBooking = location.pathname.includes(USER_BOOKINGS);

  const { code: currency = DEFAULT_CURRENCY_CODE } = useSelector(selectHotelCurrency) || {}
  let leadGuestInfo = useSelector(selectLeadGuestInfo);
  const selectedSelectBooking = useSelector(selectSelectedBooking);
  const selectedPaymentProcessRes = useSelector(selectProcessPaymentRes);

  let pricePolicyReq = getFromSessionStorage(PRICE_POLICY_REQUEST_BODY);
  let bookingInformation = getFromSessionStorage(BOOKING_INFORMATION);

  if (isNonVoucheredBooking) {
    pricePolicyReq = get(selectedSelectBooking, "bookingReqJson", {});
    bookingInformation = selectedPaymentProcessRes;
    leadGuestInfo = getLeadGuestInfo(
      get(pricePolicyReq, "hotelRoomsDetails.0.hotelPassenger", [])
    );
  }
  const hotelCode = get(pricePolicyReq, "hotelCode", EMPTY_STRING);
  const { HotelName = "" } = pricePolicyReq || {};

  const session =
    get(
      bookingInformation,
      `${isNonVoucheredBooking ? "session" : "output.session"}`
    ) || {};
  const id = get(session, "id", "");
  const sessionId = get(session, "session_id", "");
  const grandTotal = get(session, "amount", ZERO);
  const tavaBookingId = isNonVoucheredBooking
    ? get(selectedSelectBooking, "tavaBookingId", "")
    : get(bookingInformation, "tavaBookingId", "");

  const {
    email,
    phoneNumber,
    firstName: name,
  } = get(leadGuestInfo, "profileDetails", {});

  const handlePaymentFailure = (razorpayPaymentObject) => {
    const queryParams = {
      oderId: sessionId,
      serviceType: HOTEL,
    };
    dispatch(
      getPaymentMethod({
        queryParams,
      })
    ).then(() => {
      navigate(HOTEL_CONFIRM.replace(":hotelId", hotelCode));
      closeRazorPayModal(razorpayPaymentObject);
      const razorpayContainer = document.querySelector(".razorpay-container");
      // Check if the Razorpay container exists, then remove it
      if (razorpayContainer) {
        razorpayContainer.remove();
      }
      const body = document.querySelector('body');
      // Remove the inline style for overflow
      body.style.overflow = '';
    });
  };

  const handleRazorpayPayment = () => {
    let paymentAPIReq = {
      source: "TBO",
      paymentSessionId: id,
      tavaBookingId,
      paymentGateway: DEFAULT_PAYMENT_GATEWAY,
    };

    if (isNonVoucheredBooking) {
      paymentAPIReq = {
        ...paymentAPIReq,
        EndUserIp: get(pricePolicyReq, "EndUserIp", ""),
      };
    }

    const abortHotelBookingReqBody = {
      source: "TBO",
      bookingStatus: CANCELLED,
      paymentStatus: CANCELLED,
    };

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency,
      amount: grandTotal,
      order_id: sessionId,
      name: HOTELS_PAYMENT_MODAL_TITLE,
      description: `TavaTrip Hotel Booking for ${HotelName}`,
      handler: (e) => {
        dispatch(setHotelBookCallbackRes({}));
        const paymentId = e.razorpay_payment_id;
        const queryParams = { ...paymentAPIReq, paymentId };
        if (isNonVoucheredBooking) {
          dispatch(setIsGenerateVoucherInitiated(true));
          dispatch(
            generatePaymentVoucher({
              queryParams,
            })
          ).then(() => {
            dispatch(
              getUserBookingInfo({
                queryParams: {
                  division: HOTEL,
                  id: tavaBookingId,
                },
              })
            );
          });

          navigate(
            BOOKING_INFO_ROUTE.replace(":division", HOTEL).replace(
              ":id",
              tavaBookingId
            )
          );
        } else {
          dispatch(
            hotelBookCallback({
              queryParams,
            })
          );
          navigate(HOTEL_CONFIRM.replace(":hotelId", hotelCode));
        }
      },
      modal: {
        ondismiss: () => {
          dispatch(setHotelBookCallbackRes({}));
          dispatch(
            updateHotelBookingStatus({
              requestBody: abortHotelBookingReqBody,
              id: tavaBookingId,
            })
          );
          navigate(HOTEL_CONFIRM.replace(":hotelId", hotelCode));
        },
      },
      prefill: {
        name,
        email,
        contact: phoneNumber,
      },
    };
    const rp = new window.Razorpay(options);
    setRazorpayPaymentObject(rp);
    document.body.style.overflow = "visible";
    rp.on("payment.failed", () => {
      handlePaymentFailure(rp);
    });
  };

  useEffect(() => {
    if (!isEmpty(sessionId)) {
      if (id === MOCK_SESSION_ID) {
        dispatch(hotelBookCallback({ queryParams: {} }));
        navigate(HOTEL_CONFIRM);
      } else handleRazorpayPayment();
    } else navigate(HOTEL_REVIEW.replace(":hotelId", hotelCode));

    return () => {
      if (!isEmpty(razorpayPaymentObject))
        closeRazorPayModal(razorpayPaymentObject);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(razorpayPaymentObject)) {
      razorpayPaymentObject.open();
    }
  }, [razorpayPaymentObject]);

  return (
    <Helmet>
      <title>{brandName} | Hotel Payment</title>
    </Helmet>
  );
};

export default HotelPayment;
