import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { KebabMenu, CircledExclaimation, RenderSVG } from "../../assets/icons";
import {
  calculateTotalDuration,
  formatDateWithLeadingZeros,
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayDiffrence,
  getFormattedDate,
  getFormattedDateObject,
  getFormattedDuration,
  getFormattedLongDate,
  getLayoverDuration,
  useClickOutside,
  mapPaymentReq,
  loadScript,
  getLeadGuestInfo,
  getFutureFormattedDateObject,
} from "../../helper";
import {
  getAmadeusRefundCharges,
  getTBORefundCharges,
  setSelectedBooking,
  processPayment,
  setUserBookingInformation,
  setIsGenerateVoucherInitiated,
} from "./index";
import mapCancellationRequest from "../../RequestMappers/CancellationRequestMapping";
import { setSelectedModal } from "../../components/organisms/Modal";
import { selectCountryInfo } from "../Profile";
import { MODALS } from "../../components/organisms/AppModals/modals.constants";
import {
  DEFAULT_VALUES,
  FLIGHT_PROVIDERS,
  BOOKING_CATEGORIES,
  ROUTES,
  RAZORPAY_CHECKOUT_SCRIPT_URL,
  SEARCH_SECTION,
  IS_VOUCHERED_BOOKING,
} from "../../constants";
import logEvent from "../../utils/GATracker";

const { ONE, ZERO, NEG_ONE, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;
const { TBO } = FLIGHT_PROVIDERS;
const { BOOKING_CANCELLATION_MODAL } = MODALS;
const { UPCOMING, CANCELED, UNSUCCESSFUL, COMPLETED } = BOOKING_CATEGORIES;
const { HOTEL_NON_VOUCHERED_PAYMENT, BOOKING_INFO_ROUTE , HOTEL_CANCELLATION} = ROUTES;
const { HOTEL, FLIGHT } = SEARCH_SECTION;

const FlightCard = ({ itinerary, showBorder, isMultipleBooking }) => {
  const { t } = useTranslation();
  const {
    duration = EMPTY_STRING,
    segments = EMPTY_ARRAY,
    source = EMPTY_STRING,
  } = itinerary;
  const {
    carrierName: airlineName,
    carrierCode: airlineCode,
    flightNumber,
    aircraftCode,
  } = get(segments, "0", EMPTY_OBJECT);
  const isTBOBooking = source === TBO;
  const layoverDuration = getLayoverDuration(segments);
  const noOfStops = segments.length - ONE;
  const departureDate = get(segments, "0.departure.date", EMPTY_STRING);
  const flightDepartureDate = getFormattedDate(departureDate);
  const arrivalDate = get(segments, `${noOfStops}.arrival.date`, EMPTY_STRING);
  const flightArrivalDate = getFormattedDate(arrivalDate);
  const arrivalIataCode = get(
    segments,
    `${noOfStops}.arrival.iataCode`,
    EMPTY_STRING
  );
  const departIataCode = get(segments, "0.departure.iataCode", EMPTY_STRING);
  const arrivalCity = getCityNameByIata(arrivalIataCode);
  const destinationCity = getCityNameByIata(departIataCode);
  const airlineInformation = `${airlineName} ${airlineCode} ${flightNumber} ${
    aircraftCode ? `| ${aircraftCode}` : EMPTY_STRING
  }`;
  const arrivalAt = get(segments, `${noOfStops}.arrival.time`, EMPTY_STRING);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  const dateTimeString = `${departureDate}T${segments[ZERO].departure.time}`;
  const arrivalDateTimeString = formatDateWithLeadingZeros(
    `${arrivalDate}T${arrivalAt}`
  );
  const departureTime = getFormattedLongDate(
    `${formatDateWithLeadingZeros(dateTimeString)}`,
    options
  ).split(",")[ONE];
  const arrivalTime = getFormattedLongDate(
    arrivalDateTimeString,
    options
  ).split(",")[ONE];
  const resultantDuration = isTBOBooking
    ? calculateTotalDuration(layoverDuration, duration)
    : getFormattedDuration(duration);
  return (
    <div
      className={classNames({
        "bg-white ": !isMultipleBooking,
        "border-dashed border-gray-500": showBorder,
      })}
    >
      <div
        className={classNames({
          "bg-white  gap-4": isMultipleBooking,
        })}
      >
        <div className="grid grid-cols-12 gap-4 px-6 md:px-7 pt-5 pb-5">
          <div className="col-span-12 lg:col-span-3  flex gap-3">
            <div className="flight-logo flex-shrink-0">
              <img
                className="col-span-1 w-[32px] h-[35px] md:w-[40px] md:h-[40px]"
                src={getAirlineIconUrlByCarrierCode(airlineCode)}
                alt={`${airlineCode}_logo`}
              />
            </div>
            <div className="flex-1">
              <div>
                <div className="text-sm xl:text-base">
                  {departureTime} - {arrivalTime}
                </div>
              </div>
              <div className="text-[10px] md:text-[12px] font-light text-zinc-500">
                {airlineInformation}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-8 lg:col-span-7 flex gap-4 items-center text-center justify-between lg:justify-around">
            <div className="text-center">
              <h4 className="text-xl font-bold text-gray-900">
                {destinationCity}
              </h4>
              <span className="text-xs font-light ml-1 ">{`(${flightDepartureDate})`}</span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-sm">{resultantDuration}</div>
              <div className="border-t-2 border-orange-500  w-36 my-1" />
              <div className="text-sm text-gray-700 font-medium">
                {departIataCode}-{arrivalIataCode}
              </div>
            </div>
            <div>
              <h4 className="text-xl font-bold text-gray-900">{arrivalCity}</h4>
              <span className="text-xs font-light ml-1">{`(${flightArrivalDate})`}</span>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 lg:col-span-2  flex flex-col  items-center md:items-end text-end">
            <div>
              {!noOfStops ? (
                <h5 className="mr-2 text-sm font-medium text-left">
                  {t("flightResults.stopTypes.nonStop")}
                </h5>
              ) : (
                <div className="flex flex-col items-center">
                  <h5 className="mr-2 text-sm font-medium">
                    {noOfStops} {t("flightResults.stopTypes.stop")}
                  </h5>
                  <div className="text-[10px] md:text-[12px] font-light text-zinc-500 whitespace-nowrap">
                    {layoverDuration.map((flightDuration, index) => (
                      <div key={segments[index].arrival.iataCode}>
                        {flightDuration}&nbsp;
                        {getCityNameByIata(segments[index].arrival.iataCode)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getLeadpaxName = (passengers) => {
  const leadPax = passengers.map((passenger) => {
    const { firstName, middlename, lastName } = passenger;
    return `${firstName || ""} ${middlename || ""} ${lastName || ""}`;
  })  
  const leadPaxName = leadPax.join(); // Join names with a space  
  return leadPaxName;
};

const HotelCard = ({ bookingDetails }) => {  
  const { blockRoomResJson, blockRoomReqJson, bookingReqJson } = bookingDetails || {};  
  const { hotelName, addressLine1 } = blockRoomResJson.blockRoomResult || {};
  const {
    hotelPassenger,
  } = bookingReqJson.hotelRoomsDetails[ZERO] || {};  
  const {
    dayRates,
    childCount = 0,
  } = blockRoomResJson?.blockRoomResult?.hotelRoomsDetails?.[ZERO] || {};
  
  const leadPax = getLeadpaxName(hotelPassenger);
  let totalDays;
  let checkIn;
  let checkOut;
  if (isEmpty(dayRates)) {
    checkIn = (blockRoomReqJson?.checkInDate) && getFormattedDateObject(blockRoomReqJson?.checkInDate.replace('/','-').split('-').reverse().join('-'))
    checkOut = (blockRoomReqJson?.checkOutDate) && getFormattedDateObject(blockRoomReqJson?.checkOutDate.replace('/','-').split('-').reverse().join('-'));
    totalDays = (blockRoomReqJson?.checkInDate && blockRoomReqJson?.checkOutDate) && (checkOut.date - checkIn.date - ONE);
  } else {
    const checkInDateObject = dayRates?.[ZERO];
    const checkOutDateObject = dayRates?.slice(NEG_ONE)[ZERO];

    if ((checkInDateObject, checkOutDateObject)) {
      checkIn = getFormattedDateObject(checkInDateObject.date);
      checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
      totalDays = getDayDiffrence(
        checkInDateObject.Date,
        checkOutDateObject.Date
      );
    }
  }
  const hotelBookingDuration = totalDays + ONE || ONE;
  const totalRooms = bookingReqJson.hotelRoomsDetails?.length;
  const totalPassengers = bookingReqJson.hotelRoomsDetails?.[0]?.hotelPassenger?.length;
  const paxTypeAndCount = {
    adult: totalPassengers ? totalPassengers - childCount : ZERO,
    child: childCount,
  };
  return (
    <>
      <div className="flex gap-6 px-4 py-3 border-b border-dashed border-gray-300 justify-between">
        <div className="flex-1">
          <h4 className="text-gray-900 font-bold text-base mb-2">
            {hotelName || blockRoomReqJson?.hotelName}
          </h4>
          <p className="text-gray-500 text-xs mb-2">{addressLine1}</p>
        </div>
      </div>

      <div className="p-4 bg-indigo-50/30">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12 lg:col-span-6">
            <div className="flex gap-3 justify-between items-center">
              <div className="">
                <span className="text-gray-500 text-xs font-medium">
                  Check In
                </span>
                <h5 className="text-xs  text-gray-900 mb-1">
                  {checkIn ? (
                    <>
                      {checkIn.day}{" "}
                      <strong className="text-sm">
                        {`${checkIn.date} ${checkIn.month} `}
                      </strong>
                      {checkIn.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
              <div className="icon shrink-0">
                <div className="bg-gray-200 font-medium text-gray-700 rounded-2xl px-3 py-0.5 text-[10px]">
                  {`${hotelBookingDuration} Night${
                    hotelBookingDuration > ONE ? "s" : ""
                  }`}
                </div>
              </div>
              <div className="">
                <span className="text-gray-500 text-xs font-medium">
                  Check Out
                </span>
                <h5 className="text-xs  text-gray-900 mb-1">
                  {checkOut ? (
                    <>
                      {checkOut.day}{" "}
                      <strong className="text-sm">
                        {`${checkOut.date} ${checkOut.month} `}
                      </strong>
                      {checkOut.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-12 lg:col-span-6 flex justify-between md:justify-around gap-4">
            <div className="text-start">
              <span className="text-gray-500 text-xs font-medium">
                Guest &amp; Room Type
              </span>
              <h5 className="text-xs text-gray-800 font-bold mb-1 flex">
                <p className="flex flex-col p-3">
                  <div>{`${paxTypeAndCount.adult} x Adult`}</div>
                  {paxTypeAndCount.child ? (
                    <div>{`${paxTypeAndCount.child} x Child`}</div>
                  ) : (
                    EMPTY_STRING
                  )}
                </p>
                <p className="p-3 border-l-2 border-black">{`${totalRooms} x Room`}</p>
              </h5>
            </div>
            <div>
              <span className="text-gray-500 text-xs font-medium">
                Primary Guest
              </span>
              <h5 className="text-xs  text-gray-900 mb-1 font-bold">
                {leadPax}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BookingInfoCard = ({ booking = {}, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const selectedCountryInfo = useSelector(selectCountryInfo);

  useClickOutside(menuRef, setIsDropdownOpen);
  const menuBtnClicked = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  let bookingDetails = booking;
  let isMultipleBooking;
  const isFlightBooking = !booking.hasOwnProperty(IS_VOUCHERED_BOOKING);
  const shouldShowPaymentTags =
    !isFlightBooking && !booking.paymentId && type === UPCOMING;
  const source = get(booking, "provider", EMPTY_STRING);

  const TABS = [
    {
      id: 1,
      name: t("userBookings.actions.view"),
      show: true,
      disabled: false,
      handleOnClick: () => {
        const division = isFlightBooking ? FLIGHT : HOTEL;
        dispatch(setIsGenerateVoucherInitiated(false));
        dispatch(
          setUserBookingInformation({
            result: [booking],
            type,
          })
        );
        navigate(
          BOOKING_INFO_ROUTE.replace(":division", division).replace(
            ":id",
            booking.tavaBookingId
          )
        );
        setIsDropdownOpen(false);
      },
    },
    {
      id: 2,
      name: t("userBookings.actions.share"),
      show: false,
      disabled: false,
      handleOnClick: () => {
        setIsDropdownOpen(false);
      },
    },
    {
      id: 3,
      name: t("userBookings.actions.reschedule.title"),
      show: false,
      disabled: false,
      handleOnClick: () => {
        setIsDropdownOpen(false);
      },
    },
    {
      id: 4,
      name: isFlightBooking
        ? t("userBookings.actions.cancelFlight")
        : t("userBookings.actions.cancelHotel"),
      show: type === UPCOMING,
      disabled: false,
      handleOnClick: () => {
        if (!isFlightBooking) {
          const bookingId = booking.tavaBookingId;
          navigate(HOTEL_CANCELLATION.replace(":bookingId", bookingId));
          return;
        }
        let bookingInfo = { ...booking };
        bookingInfo.endUserIp = endUserIp;
        const cancellationRequestBody = mapCancellationRequest(
          source,
          bookingInfo
        );
        source === TBO
          ? dispatch(getTBORefundCharges({ body: cancellationRequestBody }))
          : dispatch(
              getAmadeusRefundCharges({ body: cancellationRequestBody })
            );
        dispatch(setSelectedBooking(bookingInfo));
        dispatch(setSelectedModal(BOOKING_CANCELLATION_MODAL));
        setIsDropdownOpen(false);
      },
    },
    {
      id: 5,
      name: t("userBookings.actions.payNow"),
      show: shouldShowPaymentTags,
      disabled: isSpinnerActive,
      handleOnClick: () => {
        initiatePayment(booking);
        setIsDropdownOpen(false);
      },
    },
  ].filter(({ show }) => show);

  if (isFlightBooking) {
    bookingDetails = get(
      booking,
      "bookingJSON.journeyDetails.0.itineraries",
      []
    );
    isMultipleBooking = bookingDetails.length > ONE;
  }

  const initiatePayment = async (booking) => {
    logEvent(`initiate_payment`)
    dispatch(setSelectedBooking(booking));
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;

    setIsSpinnerActive(true);
    const leadGuest = getLeadGuestInfo(
      get(booking, "bookingReqJson.hotelRoomsDetails.0.hotelPassenger", [])
    );
    const blockRoomRes = get(booking, "blockRoomResJson", {});

    if (isEmpty(blockRoomRes)) return;

    const paymentProcessRequest = mapPaymentReq({
      userCountryInfo: selectedCountryInfo,
      blockRoomRes,
      leadGuest,
    });
    const hotelCode = get(booking, "bookingReqJson.hotelCode", "");

    dispatch(processPayment({ body: paymentProcessRequest })).then(() => {
      setIsSpinnerActive(false);
      navigate(HOTEL_NON_VOUCHERED_PAYMENT.replace(":hotelId", hotelCode));
    });
  };

  return (
    <div className="md:w-full border border-dashed border-contrast-300 rounded-lg overflow-hidden relative">
      <div
        className={classNames(
          "flex flex-wrap px-4 py-3 border-b border-dashed border-gray-300 justify-between",
          {
            "bg-primary-100/50": type === UPCOMING,
            "bg-red-100/50": type === UNSUCCESSFUL,
            "bg-yellow-100/50": type === CANCELED,
            "bg-gray-100/50": type === COMPLETED,
          }
        )}
      >
        <div className="text-gray-800 font-semibold text-xs flex justify-center items-center">
          Booking ID{" "}
          <span className="mx-1 text-primary-700 bg-primary-100 px-2 py-0.5 rounded-2xl">
            {booking.tavaBookingId}
          </span>
          {shouldShowPaymentTags && (
            <div className="flex mx-1 text-yellow-800 bg-yellow-100 px-2 py-0.5 rounded-2xl border border-yellow-800">
              <RenderSVG
                Svg={CircledExclaimation}
                className="w-[15px] h-[15px] mr-1"
                alt="dashboard-icon"
              />
              Payment Due
            </div>
          )}
        </div>
        <div className="flex gap-1">
          {booking.pnr && (
            <p className="text-xs text-gray-400 bg-primary-100/50 border border-primary-600 inline-flex rounded-2xl overflow-hidden">
              <span className="px-2 py-0.5 bg-primary-600 text-white">PNR</span>
              <span className="font-bold text-gray-900 px-2 py-0.5">
                {booking.pnr}
              </span>
            </p>
          )}
          <button onClick={menuBtnClicked}>
            <RenderSVG Svg={KebabMenu} width="20" height="20" />
          </button>
        </div>
        {isDropdownOpen && !isEmpty(TABS) && (
          <div
            ref={menuRef}
            className="absolute z-10 top-9 right-1 rounded-lg shadow-2xl bg-white p-2"
          >
            <ul className="bg-white divide-y w-max flex flex-col gap-1">
              {TABS.map(({ id, handleOnClick, name, disabled }) => (
                <li key={id}>
                  <button
                    className={classNames(
                      "text-sm font-light text-gray-900 text-left px-4 py-1 cursor-pointer w-full hover:text-primary-700 mb-1 disabled:cursor-not-allowed",
                      {
                        "opacity-50": disabled,
                      }
                    )}
                    onClick={handleOnClick}
                    disabled={disabled}
                  >
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {isFlightBooking ? (
        <div
          className={classNames("flex flex-col ", {
            "mt-3 bg-white": !isMultipleBooking,
          })}
        >
          {bookingDetails.map((itinerary, index) => (
            <FlightCard
              key={uuid()}
              itinerary={itinerary}
              showBorder={Boolean(index)}
              isMultipleBooking
            />
          ))}
        </div>
      ) : (
        <HotelCard key={bookingDetails.id} bookingDetails={bookingDetails} />
      )}
    </div>
  );
};
export default BookingInfoCard;
