import { DEFAULT_VALUES, BOOKING_STATUS } from "../../../constants";
import { DownloadIcon, RenderSVG } from "../../../assets/icons";
import { Column } from "ag-grid-community";

const { EMPTY_STRING } = DEFAULT_VALUES;
const { PENDING, CONFIRMED, CANCELED, CAPTURED, AWAITINGPAYMENT, FAILED } = BOOKING_STATUS;

const getFormattedDate = (value) => {
  const date = new Date(value);
  const options = { weekday: "short", month: "long", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return (
    <div className="text-gray-800">
      <span className="font-semibold">{formattedDate}</span>
    </div>
  );
};

const getdownloadIcon = () => (
  <div className="flex items-center justify-center p-2 bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 cursor-pointer hover:bg-gray-100">
    <RenderSVG
      Svg={DownloadIcon}
      className="h-6 text-blue-600 transition-transform transform hover:scale-110"
    />
    <span className="ml-2 text-sm text-gray-700 font-medium transition-colors duration-200 hover:text-blue-600">
      Download
    </span>
  </div>
);
const getVoucheredValue=(value)=>{
  const res = value ? "Vouchered" : "Non Vouchered";
  const isVouchered = value ? "text-green-800" : "text-red-800";
  return (
    <div className={`p-2 rounded-md ${isVouchered}`}>
      <span className="font-semibold">{res}</span>
    </div>
  );
}
const getNaviagtionMark = (value) => {
  return (
    <div>
      <span className="text-blue-500 cursor-pointer font-semibold text-sm" >
        {value}
      </span>
    </div>
  );
}
const getCancellationStatus = (value) => {
  let textColor = (value === "NOT APPLIED") ? "text-green-800" : "text-red-800"
  return (
    <div className={textColor}>
      <span className="font-semibold">{value}</span>
    </div>

  )
}
const getPaymentId = (value) => {
  return (
    <div>
      <span className={`font-semibold ${value ? 'text-green-800 font-semibold' : 'text-red-800'}`}>
        {value ? value : "N/A"}
      </span>
    </div>
  )
}
const getStyledCell = (value) => {
  const bgColor =
    {
      [PENDING]: { backgroundColor: "#fef3c7", color: "#b45309" },
      [AWAITINGPAYMENT]: { backgroundColor: "#fef3c7", color: "#b45309" },
      [CONFIRMED]: { backgroundColor: "#ccfbf1", color: "#0f766e" },
      [CANCELED]: { backgroundColor: "#fee2e2", color: "#b91c1c" },
      [CAPTURED]: { backgroundColor: "#ccfbf1", color: "#0f766e" },
      [FAILED]: { backgroundColor: "#fee2e2", color: "#b91c1c" }
    }[value?.trim().toUpperCase()] || EMPTY_STRING;
  const cellStyle = {
    textAlign: "center",
    padding: "4px 15px",
    borderRadius: "15px",
    ...bgColor,
  };

  return (
    <div>
      <div>
        <span style={cellStyle} className={`font-semibold ${value.trim() != "NA" ? "" : 'text-red-800'}`}>
          {value.trim() != "NA" ? (value.trim() !== "AWAITINGPAYMENT" ? (value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase()) : "Pending") : "N/A"}
        </span>
      </div>
    </div>
  );
};

const StyledCellContent = ({ columnKey, value }) => {
  if (
    columnKey === "status" ||
    columnKey === "paymentStatus" ||
    columnKey === "ticketingStatus" ||
    columnKey === "bookingStatus"
  )
    return getStyledCell(value);
  else if (columnKey === "tavaBookingId") return getNaviagtionMark(value);
  else if (columnKey === "createdAt" || (columnKey === "updatedAt" && value) || (columnKey === "checkInDate")) return getFormattedDate(value);
  else if (columnKey == "bookingData") return getdownloadIcon();
  else if (columnKey === "isVoucheredBooking") return getVoucheredValue(value);
  else if (columnKey === "cancelationStatus") return getCancellationStatus(value);
  else if (columnKey === "paymentId") return getPaymentId(value);
  else return (
    <div className="text-gray-800">
      <span className={`font-semibold ${value ? '' : 'text-red-800'}`}>
        {value ? value : "N/A"}
      </span>
    </div>
  );
};

export default StyledCellContent;
