import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFormattedDateAndTime,
  renderStars,
  getFormattedAddress,
  getFormattedDateObject,
  getDayDiffrence,
  getFutureFormattedDateObject,
} from "../../../helper";
import {
  CURRENCY_SYMBOLS,
  ROUTES,
  SEARCH_SECTION,
  DEFAULT_VALUES,
} from "../../../constants";
import {
  getUserBookingInfo,
  setIsGenerateVoucherInitiated,
} from "../../../screens/MyTrips";
import { BOOKING_CATEGORIES } from "../../../constants";

const { BOOKING_INFO_ROUTE } = ROUTES;
const { HOTEL } = SEARCH_SECTION;
const { EMPTY_STRING, ZERO, EMPTY_ARRAY, ONE } = DEFAULT_VALUES;
const { CONFIRMED } = BOOKING_CATEGORIES;

const BookingSummary = ({
  hotelInfo,
  bookingInfo,
  customerInfo,
  isVoucherBooking,
  isSpinnerActive,
  initiatePayment,
  isPayNowSpinnerActive,
  showPayNowOption,
  showNavigateToMyTripOption,
  type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    hotelBookingStatus = EMPTY_STRING,
    addressLine1 = EMPTY_STRING,
    hotelImages = EMPTY_STRING,
    hotelName = EMPTY_STRING,
    starRating = EMPTY_STRING,
    cancellationMsgArr = EMPTY_STRING,
  } = hotelInfo;

  const {
    lastCancellationDate = EMPTY_STRING,
    tavaBookingId = EMPTY_STRING,
    bookingId = EMPTY_STRING,
    paymentId = EMPTY_STRING,
    invoiceNo = EMPTY_STRING,
    noOfRooms = ZERO,
    amount = ZERO,
    currency = EMPTY_STRING,
    dayRates = EMPTY_ARRAY,
  } = bookingInfo;

  const checkInDateObject = dayRates?.[ZERO];
  const checkOutDateObject = dayRates?.slice(-1)[ZERO];
  let checkIn;
  let checkOut;
  let totalDays;
  if ((checkInDateObject, checkOutDateObject)) {
    checkIn = getFormattedDateObject(checkInDateObject.date);
    checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
    totalDays = getDayDiffrence(
      checkInDateObject.date,
      checkOutDateObject.date
    );
  }
  const hotelBookingDuration = totalDays + ONE || ONE;
  const {
    firstName = EMPTY_STRING,
    lastName = EMPTY_STRING,
    title = EMPTY_STRING,
    adults = ZERO,
    children = ZERO,
  } = customerInfo;

  const adultsText = `${adults} Adult${adults > 1 ? "s" : ""}`;
  const childsCountText = children > 1 ? "'s" : "";
  const childsText =
    children > 0 ? `| ${children} Child${childsCountText}` : "";
  const roomsText = `${noOfRooms} Room${noOfRooms > 1 ? "s" : ""}`;

  const handleNavigateToBookingDetailsPage = () => {
    dispatch(setIsGenerateVoucherInitiated(false));
    dispatch(
      getUserBookingInfo({
        queryParams: {
          division: HOTEL,
          id: tavaBookingId,
        },
      })
    );
    navigate(
      BOOKING_INFO_ROUTE.replace(":division", HOTEL).replace(
        ":id",
        tavaBookingId
      )
    );
  };

  return (
    <div className="bg-white rounded-lg border border-dashed border-gray-300 shadow-sm">
      {!isVoucherBooking &&
        hotelBookingStatus &&
        (!type || (type && type === "UPCOMING")) && (
          <div className="px-4 py-3">
            <div
              className={classNames(
                "bg-teal-50/50 border border-teal-500 rounded-lg p-3",
                {
                  "flex flex-col sm:flex-row gap-2": showNavigateToMyTripOption,
                }
              )}
            >
              <div>
                <table className="text-sm font-semibold w-full">
                  <tbody>
                    <tr>
                      <td className="text-teal-900 py-2">
                        Amount Pending
                        <p className="font-normal text-xs text-teal-600">
                          Pay the remaining amount {CURRENCY_SYMBOLS[currency]}{" "}
                          {amount} using any payment option before{" "}
                          {getFormattedDateAndTime(lastCancellationDate)} to
                          avoid auto cancellation
                        </p>
                      </td>
                      <td className="text-black py-2 px-4 whitespace-nowrap">
                        {CURRENCY_SYMBOLS[currency]}
                        {amount}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-teal-900 py-2">Total Price</td>
                      <td className="text-black py-2 px-4 whitespace-nowrap">
                        {CURRENCY_SYMBOLS[currency]}
                        {amount}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {showPayNowOption && (
                  <div className="flex justify-end px-4 py-2">
                    <button
                      onClick={() => initiatePayment()}
                      className={classNames(
                        "py-2 px-4 rounded-md bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-600 shadow-sm text-xs text-white font-medium disabled:cursor-not-allowed",
                        {
                          "opacity-50": isPayNowSpinnerActive,
                        }
                      )}
                      disabled={isPayNowSpinnerActive}
                    >
                      Pay Now
                    </button>
                  </div>
                )}
              </div>
              {!isVoucherBooking && showNavigateToMyTripOption && (
                <div className="px-4 border-t sm:border-t-0  sm:border-l border-dashed border-teal-500 py-4 flex items-center">
                  <div className="whitespace-nowrap text-sm font-semibold text-center flex-1 w-full">
                    <span width="300" className="text-gray-700 block mb-1">
                      If you want to pay
                    </span>
                    <button
                      className="text-[#4338ca] disabled:cursor-not-allowed"
                      disabled={isSpinnerActive}
                      onClick={() => handleNavigateToBookingDetailsPage()}
                    >
                      Go to My Trips
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      <div className="flex gap-6 px-4 py-3 border-b border-dashed border-gray-300 justify-between">
        <h4 className="text-gray-900 font-bold text-sm">Summary</h4>
        {bookingId && (
          <p className="text-gray-800 font-semibold text-xs">
            Booking ID:{" "}
            <span className="text-[#4338ca] bg-[#e0e7ff] px-2 py-0.5 rounded-2xl">
              {bookingId}
            </span>
            {paymentId && invoiceNo && (
              <span>
                {" | Invoice ID: "}
                <span className="text-[#4338ca] bg-[#e0e7ff] px-2 py-0.5 rounded-2xl">
                  {invoiceNo}
                </span>
              </span>
            )}
          </p>
        )}
      </div>
      <div className="flex gap-6 px-4 py-3 border-b border-dashed border-gray-300 justify-between">
        <div className="flex-1">
          <h4 className="text-gray-900 font-bold text-base mb-2">
            {hotelName}
          </h4>
          <p className="text-gray-700 text-xs mb-2">
            {getFormattedAddress(addressLine1)}
          </p>
          <div className="flex gap-2 items-center mb-2">
            <div className="flex items-center gap-0.5">
              {renderStars(starRating)}
            </div>
          </div>
        </div>
        {hotelImages && <div className="shrink-0">
          <img
            src={hotelImages}
            className="rounded-md h-28"
            width="100"
            alt="Hotel Room"
          />
        </div>}
      </div>
      <div className="p-4 bg-indigo-50/30">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12 lg:col-span-6">
            <div className="flex gap-3 justify-between items-center">
              <div>
                <span className="text-gray-600 text-xs font-medium">
                  Check In
                </span>
                <h5 className="text-xs  text-gray-900 mb-1 font-bold">
                  {checkIn ? (
                    <>
                      {checkIn.day}{" "}
                      <strong className="text-sm">
                        {`${checkIn.date} ${checkIn.month} `}
                      </strong>
                      {checkIn.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
              <div className="icon shrink-0">
                <div className="bg-gray-200 font-medium text-gray-800 rounded-2xl px-3 py-0.5 text-[10px]">
                  {`${hotelBookingDuration} Night${
                    hotelBookingDuration > 1 ? "s" : ""
                  }`}
                </div>
              </div>
              <div>
                <span className="text-gray-600 text-xs font-medium">
                  Check Out
                </span>
                <h5 className="text-xs text-gray-900 mb-1 font-bold">
                  {checkOut ? (
                    <>
                      {checkOut.day}{" "}
                      <strong className="text-sm">
                        {`${checkOut.date} ${checkOut.month} `}
                      </strong>
                      {checkOut.year}
                    </>
                  ) : (
                    "..."
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-12 lg:col-span-6 flex justify-between md:justify-around gap-4">
            <div className="text-end">
              <span className="text-gray-600 text-xs font-medium">
                Guest & Rooms
              </span>
              <h5 className="text-xs text-gray-800 font-bold mb-1">
                {adultsText} {childsText} | {roomsText}
              </h5>
            </div>
            <div>
              <span className="text-gray-600 text-xs font-medium">
                Primary Guest
              </span>
              <h5 className="text-xs  text-gray-900 mb-1 font-bold">
                {title} {firstName} {lastName}
              </h5>
            </div>
          </div>
        </div>
      </div>
      {hotelBookingStatus === CONFIRMED && <div className="p-4 bg-amber-50">
        <div className="flex items-center gap-2 pl-2">
          <ul className="text-xs text-green-700 flex flex-col !list-disc">
            {cancellationMsgArr}
          </ul>
        </div>
      </div>}
    </div>
  );
};

export default BookingSummary;
