import { useMemo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { get, debounce, isEmpty } from "lodash";
import { Formik, Form, useFormikContext } from "formik";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import {
  getObjectFromQueryParams,
  getQueryParams,
  getNumberOfNights,
} from "../../../helper";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  SearchIcon,
} from "../../../assets/icons";
import {
  RoomAndGuestsCount,
  DateSelector,
  HotelsLocationPicker,
} from "../../molecules";
import {
  setCurrentSearchLevel,
  setHotels,
  setHotelsStaticData,
  setIsHotelsLoaded,
  setIsStaticDataLoaded,
  setSearchHotelFilters,
} from "./index";
import { getHotelLocations } from "./search.actions";
import { selectCountryInfo } from "../../../screens/Profile";
import {
  SEARCH_SECTION,
  DEFAULT_VALUES,
  ROUTES,
  DEBOUNCE_TIME,
  HOTEL_DEFAULT_VALUES,
  DATE_FORMAT,
  HOTEL_DESTINATION_TYPES,
} from "../../../constants";
import { selectSelectedPrediction } from "./search.selectors";
import { actions as modalActions } from "../Modal/modal.reducers";
import { actions as drawerActions } from "../Drawer/drawers.reducers";

const { HOTEL } = SEARCH_SECTION;
const { HOTEL_RESULTS } = ROUTES;
const { EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY, ZERO, ONE } = DEFAULT_VALUES;
const { CITY } = HOTEL_DESTINATION_TYPES;
const { setSelectedDrawer } = drawerActions;
const { setSelectedModal } = modalActions;

const MAX_NUMBER_OF_NIGHTS = 31;

const getNextDate = (dateString) => {
  const originalDate = new Date(dateString);
  const oneDayAfter = new Date(originalDate);
  oneDayAfter.setDate(originalDate.getDate() + ONE);
  return oneDayAfter.toISOString().split("T")[ZERO];
};

const formattedDate = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  const day = date.getDate();
  const month = date.getMonth() + ONE;
  const year = date.getFullYear();

  return `${String(day).padStart(2, "0")}/${String(month).padStart(
    2,
    "0"
  )}/${year}`;
};

const SearchButton = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const noOfNights = getNumberOfNights(
    new Date(values?.checkIn),
    new Date(values?.targetDate)
  );

  const isDisabled = noOfNights > MAX_NUMBER_OF_NIGHTS 
  
  return (
    <button
      className={classNames(
        "py-3 px-4 w-full h-full items-center flex gap-2 rounded-md justify-center text-sm font-medium text-white bg-secondary-600 max-h-[54px]",
        {
          "opacity-75": isDisabled,
        }
      )}
      type="submit"
      disabled={isDisabled}
      data-tooltip-id="hotel-tooltip"
      data-tooltip-place="bottom"
      data-tooltip-content={`${t("hotelResults.maxNightsWarning")}`}
    >
      {isDisabled && (
        <Tooltip
          id="hotel-tooltip"
          className="!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-50"
        />
      )}
      <RenderSVG Svg={SearchIcon} className="text-white" alt="Search Icon" />
      <span>{t("searchSection.findHotels")}</span>
    </button>
  );
};

const RenderSearchHotelSection = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + ONE);

  const minCheckoutDate = new Date(get(values, "checkIn"));
  minCheckoutDate.setDate(minCheckoutDate.getDate() + ONE);

  const debouncedLocationChange = useMemo(() => {
    const fetchHotels = (mainLocationSearchValue) => {
      const keyword = mainLocationSearchValue.inputValue
        ? mainLocationSearchValue.inputValue
        : EMPTY_STRING;
      keyword && dispatch(getHotelLocations({ key: keyword }));
    };
    return debounce(fetchHotels, DEBOUNCE_TIME);
  }, []);

  const handlecheckInChange = (values) => {
    const startDate = get(values, "0");
    const endDate = get(values, "1");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("checkIn", formattedStartDate);
    if (formattedStartDate === formattedEndDate) return;
    setFieldValue("targetDate", formattedEndDate);
  };

  const handleCloseDatePicker = () => {
    const departureDate = get(values, "checkIn");
    const endDate = get(values, "targetDate");
    !endDate && setFieldValue("targetDate", getNextDate(departureDate));
  };

  const handleLocationChange = (mainLocationSearchValue) =>
    debouncedLocationChange(mainLocationSearchValue);

  return (
    <div className="grid grid-cols-12 gap-4 2xl:gap-5 mt-[10px]">
      <div className="col-span-12 md:col-span-6 lg:col-span-3 gap-3 relative">
        <HotelsLocationPicker
          name="destination"
          handleLocationChange={handleLocationChange}
          source={HOTEL}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-4">
        <div className="flex gap-3">
          <span
            type="button"
            className="flex-1 rounded-lg bg-white flex items-center gap-3 shadow-sm"
          >
            <DateSelector
              showPrices={false}
              placeholder="Check In"
              name="checkIn"
              height="53px"
              travelType={HOTEL}
              range={true}
              showCalendarIcon={true}
              minDate={tomorrowDate}
              defaultDates={[get(values, "checkIn"), get(values, "targetDate")]}
              handleChangeFromProps={handlecheckInChange}
              onClose={handleCloseDatePicker}
            />
          </span>
          <span
            type="button"
            className="flex-1 rounded-lg bg-white flex items-center gap-3 shadow-sm"
          >
            <DateSelector
              showPrices={false}
              placeholder="Check Out"
              name="targetDate"
              height="53px"
              showCalendarIcon={true}
              minDate={minCheckoutDate}
            />
          </span>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3 relative text-black rounded-md">
        <RoomAndGuestsCount />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-2 flex flex-col md:flex-row gap-3 2xl:gap-4">
        <SearchButton />
      </div>
    </div>
  );
};

const HotelSummary = ({ selectedPrediction }) => {
  const { values } = useFormikContext();
  const { destination, checkIn, targetDate } = values;
  return (
    <div className="flex flex-center 2xl:gap-6 gap-3 me-auto 2xl:text-base lg:text-sm text-xs text-contrast-900">
      <span>
        {get(selectedPrediction, "structured_formatting.main_text", "") ||
          destination?.hotelName ||
          destination?.cityName}
      </span>
      <span>•</span>
      <span className="whitespace-pre">
        {checkIn}
        {targetDate && <span> - {targetDate}</span>}
      </span>
    </div>
  );
};

const getDestinationData = (destination) => {
  if (!isEmpty(destination))
    return destination.type === CITY
      ? { cityId: destination.destinationId }
      : {
          hotelCode: destination.destinationId,
          hotelName: destination.hotelName,
        };
  else return EMPTY_OBJECT;
};

const getMappedRoomGuests = (roomGuests) =>
  roomGuests.map(({ noOfAdults, noOfChild, childAge }) => ({
    noOfAdults,
    noOfChild,
    ...(!isEmpty(childAge) && { childAge }),
  }));

const SearchHotelSection = ({
  showEditSearch = false,
  defaultExpandedValue = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const selectedCountryInfo = useSelector(selectCountryInfo) || {};
  const { ip: endUserIp } = selectedCountryInfo;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: defaultExpandedValue,
  });
  const selectedPrediction = useSelector(selectSelectedPrediction);

  const [initialValues, setInitialValues] = useState(HOTEL_DEFAULT_VALUES);

  useEffect(() => {
    dispatch(setSelectedDrawer(null));
    dispatch(setSelectedModal(null));
  }, [dispatch]);

  useEffect(() => {
    if (!search) return;

    const {
      checkInDate,
      checkOutDate,
      noOfNights,
      countryCode,
      guestNationality,
      cityId,
      maxPrice,
      hotelCode,
      hotelName,
      noOfRooms,
      roomGuests,
      cityName,
      destinationType,
      guestCountry,
      preferredCurrency,
      maxRating,
      minRating,
    } = getObjectFromQueryParams(search);    

    const formattedInitialValues = {
      checkIn: checkInDate || EMPTY_STRING,
      noOfNights: noOfNights || ONE,
      guestNationality: {
        isoCode: guestNationality || EMPTY_STRING,
        name: guestCountry || EMPTY_STRING,
      },
      destination: {
        countryCode: countryCode || EMPTY_STRING,
        destinationId:
          Number(destinationType) === CITY
            ? Number(cityId)
            : Number(hotelCode),
        cityName: cityName,
        type: Number(destinationType),
        ...(hotelName && { hotelName }),
      },
      noOfRooms: noOfRooms || ONE,
      cityName: cityName || EMPTY_STRING,
      targetDate: checkOutDate || EMPTY_STRING,
      roomGuests,
    };
    const filtersFromQueryParam = {
      checkInDate: formattedDate(checkInDate),
      checkOutDate: formattedDate(checkOutDate),
      noOfNights,
      countryCode,
      hotelCode,
      ...(!hotelCode && { cityId }),
      preferredCurrency,
      guestNationality,
      noOfRooms,
      roomGuests: getMappedRoomGuests(roomGuests),
      maxPrice, //added
      maxRating,
      minRating,
      endUserIp,
      isProviderMapped: "true",
      guestCountry,
      cityName,
      destinationType,
      source: "TBO",
    };
    
    search && dispatch(setSearchHotelFilters(filtersFromQueryParam));
    setInitialValues(formattedInitialValues);
  }, [search]);

  const handleNavigate = (queryString) =>
    navigate(`${HOTEL_RESULTS}?${queryString}`, { replace: true });

  const handleSearchHotels = useCallback(
    (values) => {
      const checkInDate = new Date(values.checkIn);
      const checkOutDate = new Date(values.targetDate);
      const numberOfNights = getNumberOfNights(checkInDate, checkOutDate);
      values.noOfNights = numberOfNights.toString();
      const {
        checkIn,
        noOfNights,
        guestNationality,
        noOfRooms,
        destination,
        targetDate,
        roomGuests,
      } = values;
      
      if (destination) {
        const queryFilters = {
          checkInDate: formattedDate(checkIn),
          checkOutDate: formattedDate(targetDate),
          noOfNights: noOfNights,
          countryCode: get(destination, "countryCode", ""),
          preferredCurrency: get(selectedCountryInfo, "currency.code", "INR"),
          guestNationality: guestNationality.isoCode,
          noOfRooms: noOfRooms,
          roomGuests,
          maxRating: 5,
          minRating: 0,
          isTBOMapped: "true",
          endUserIp: endUserIp,
          destinationType: destination.type,
          ...getDestinationData(destination),
          timestamp: new Date().toISOString()
        };
        const queryString = getQueryParams({
          ...queryFilters,
          cityName: destination.cityName,
          guestCountry: get(guestNationality, "name", EMPTY_STRING),
          checkOutDate: formattedDate(targetDate),
        });
        dispatch(setHotels(EMPTY_OBJECT));
        dispatch(setHotelsStaticData(EMPTY_ARRAY))
        dispatch(setIsHotelsLoaded(false));
        dispatch(setIsStaticDataLoaded(false));
        handleNavigate(queryString);
      } else {
        dispatch(setCurrentSearchLevel(ONE));
        dispatch(setHotels(EMPTY_OBJECT));
        navigate(HOTEL_RESULTS, { replace: true });
      }
    },
    [dispatch, selectedCountryInfo]
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSearchHotels}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className="flex sm:flex-row flex-col gap-4 sm:items-center">
              {showEditSearch && (
                <HotelSummary selectedPrediction={selectedPrediction} />
              )}
              {showEditSearch && (
                <button
                  type="button"
                  className="py-2 px-4 flex items-center justify-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
                  {...getToggleProps()}
                >
                  <RenderSVG
                    Svg={isExpanded ? ChevronUp : ChevronDown}
                    alt="Toggle dropdown"
                  />
                  <span>{t("searchSection.editSearch")}</span>
                </button>
              )}
            </div>
            <div {...getCollapseProps()}>
              <div>
                <RenderSearchHotelSection />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchHotelSection;
